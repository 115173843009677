@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,500;1,500&display=swap');

body {
  position: relative;
    background-image: url(./componenet/img/YsWRDA.gif);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    background-attachment: fixed;
    opacity: 1;
    font-family: 'DM Sans', sans-serif !important;
}
html {
  scroll-behavior: smooth;
}
.arrowTop {
  position: fixed;
  bottom: 20px;
  right: 20px;
}
.ReactLoader{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: black;
}

.animators {
  width: 100%;
  height: 80vh;
  align-items: center;
}
.tops {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 15%);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: linear 0.4s;
}
.tops i {
  color: #198754;
}
.tops:hover {
  background-color: #198754;
  color: white;
}
.tops:hover i{
  color: white;
}
