.mediasi{
    /* width: 100%; */
    height: 400px;
}
.carousel1{
    
    overflow: hidden;
}

@media(max-width:991px){
    .mediasi{
        width: 100%;
        height: 300px;
    }
    .Multipleltems_carousel1__1WOjR{
        padding: 0% !important;
        height: auto;
        width: 100%;
    }
    .carousel1{
        padding: 0px !important;
    }
}
.satr11{
    opacity: 0.01;
    background-color: rgba(240, 255, 255, 0.637);
    color: black;
    padding: 30px;
    position: absolute;
}
.satr11:hover{
    opacity: 1;
    background-color: rgba(240, 255, 255, 0.637);
    color: black;
    width: 100%;
    height: 100%;
    margin: auto;
    text-align: center;
}