#malumot{
    border-radius: 0 !important;
}
#malumot h3 {
    font-weight: 300;
    color: #707070;
    letter-spacing: 0.7px;
}

#malumot p{
    font-size: 18px !important;
    line-height: 2;
}

#myrowbg  .card{
    background-color: transparent;
}
#myrowbg  .card a,p{
    font-size: 17px;
}
#bbbb,#cccc{
    background-color: rgba(255, 255, 255, 0.418);
    /* background-color: red; */
    border-radius: 20px;
}
#messegss{
    background-color: rgba(255, 255, 255, 0.5);
    padding: 20px;
    border-radius: 20px;
}

.imgGifSec,.card{
    background-color: transparent;
}
.index{
    color:black;
}
.index:hover{
    color: blue;
}
#fauser{
    border-radius: 50%;
    border: 1px solid black;
    padding: 3px;
    box-sizing: border-box;
    margin: 0 5px;
}
 #fasignal{
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    padding: 2px;
    box-sizing: border-box;
    margin: 0 5px;
}
.row2 .card{
    background-color: #F7F7F7;
    border: none;
    border-radius: 0;
    padding: 10px 0 0 10px;
    box-sizing: border-box;
   
}
.row2 .div{
    width: 40px;
    height: 40px;
    background-color: orangered;
    border-radius: 50%;
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.row2 #xabarlar{
    display: flex;
   align-items: center;
   border-bottom: 1px solid gray;
   padding: 10px 0;
   margin-top: 10px;
}
.row2 #xabarlar .gmail {
    color: orangered;
    font-size: 16px;
    
}
.row2 #xabarlar .gmail:hover{
    color: red;
    border-bottom: 1px solid orangered;

}
@media only screen and (max-width: 600px) {
    body {
      background-color: lightblue;
    }
    .row2 .card{
        margin-bottom: 20px;
    }
    #malumot{
        margin-top: 30px;
    }
  }
  .images77{
     background: url(https://wallpapercave.com/wp/wp2757874.gif);
     background-size: 100% 100%;
  }